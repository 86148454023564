export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
  },
  {
    title: "Mention",
    route: "mention",
    icon: "ListIcon",
  },
  {
    title: "Source",
    route: "source",
    icon: "UsersIcon",
  },
  {
    title: "Analytic",
    icon: "TrendingUpIcon",
    children: [
      {
        title: "General",
        route: "analytic-general",
        icon: "LayersIcon",
      },
      {
        title: "Maps",
        route: "analytic-maps",
        icon: "MapIcon",
      },
      {
        title: "Narasumber",
        route: "analytic-narasumber",
        icon: "UserIcon",
      },
    ],
  },
  {
    title: "Trash Bin",
    route: "trash-bin",
    icon: "Trash2Icon",
  },
  {
    title: "Setting",
    icon: "SettingsIcon",
    children: [
      {
        title: "Keyword",
        route: "setting-keyword",
        icon: "KeyIcon",
      },
      {
        title: "User",
        route: "setting-user",
        icon: "UserCheckIcon",
      },
      {
        title: "Channel",
        route: "setting-channel",
        icon: "ApertureIcon",
      },
      {
        title: "Notification & Email",
        route: "setting-notifemail",
        icon: "BellIcon",
      },
    ],
  },
];
